.App {
  font-family: sans-serif;
  text-align: center;
}
.map-container {
  height: 100vh;
  width:60vw;
}
body{
  overflow-x: hidden;
}
body::-webkit-scrollbar {
  display: none;
}
#confirmbtn{
  font-size:20px;
  width:80%;
  height:40px;
  background-color:lightgreen;
  margin-left:10%;
  margin-bottom:1pc;
  color:white;
  border-color:white;
}
#confirmbtn:hover{
  transform:scale(1.1);
  transition:all .6s;
  
}
#input-data{
  font-size:20px;
  width:40vw;
  font-family: 'FiraCode';
}
#dateinfo{
  margin-left:10%;
  margin-bottom:2%;
  margin-top:5%;
}
#noofdays{
  margin-left:13%;
}

#total{
  margin-left:10%;
  display:flex;
  flex-direction:row;
  font-weight:bold;
}
#cost{
  margin-left:13%;
}
#congrats{
  margin-left:10%;
}
#cards{
  
}